/* 
@media (max-width: 768px) {
  .snMarine .header-carousel .owl-carousel-item {
    position: relative;
    min-height: 500px;
  }

  .snMarine .header-carousel .owl-carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .snMarine .header-carousel .owl-carousel-item h5,
  .snMarine .header-carousel .owl-carousel-item p {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .header-carousel .owl-carousel-item h1 {
    font-size: 30px;
    font-weight: 600;
  }
}

.snMarine .header-carousel .owl-nav {
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.snMarine .header-carousel .owl-nav .owl-prev,
.snMarine .header-carousel .owl-nav .owl-next {
  margin: 7px 0;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 45px;
  font-size: 22px;
  transition: 0.5s;
}

.snMarine .header-carousel .owl-nav .owl-prev:hover,
.snMarine .header-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.snMarine .page-header {
  background: linear-gradient(rgba(6, 3, 21, 0.5), rgba(6, 3, 21, 0.5)),
    url("../../../public/images/carousel-1.jpg") center center no-repeat;
  background-size: cover;
}

.snMarine .breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

.snMarine .snbtn {
  color: #ffffff;
  background-color: #51cfed00;
  border-color: #fdfdfd;
  font-weight: 900;
}

.snMarine .snbtn2 {
  color: #ffffff;
  background-color: #ff3e41;
  border-color: #ff3e41;
  font-weight: 900;
} */

/* Slider.css */
.snMarine {
  position: relative;
}

.slider-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.slider-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}

/* Overlay to darken the image */
.slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 3, 21, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Centered text content */
.text-content {
  text-align: center;
  color: white;
  max-width: 800px;
}

/* Fade-in animation */
.fade-in {
  opacity: 0;
  animation: fadeIn 2s ease-in-out forwards;
}

.snMarine .snbtn {
  color: #ffffff;
  background-color: #51cfed00;
  border-color: #fdfdfd;
  font-weight: 900;
}

.snMarine .snbtn2 {
  color: #ffffff;
  background-color: #ff3e41;
  border-color: #ff3e41;
  font-weight: 900;
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .display-3 {
    font-size: 2rem;
  }

  .button-group a {
    margin-bottom: 10px;
  }
}
