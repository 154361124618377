.marine-parts-container {
  padding: 20px;
  text-align: center;
}

.marine-parts-title {
  font-size: 24px;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
}
.marine-parts-p {
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.card {
  background-color: #f0f8ff;
  border: 1px solid #007bff;
  border-radius: 8px;
  padding: 10px;

  width: 200px;
  height: 200px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 300px;
  height: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
}

.slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 300px; /* Set a consistent height for the slide */
  padding: 0 10px; /* Adds horizontal space between slides */
}

.slick-list {
  margin: 0 -5px; /* Counteracts the padding on each slide for smooth alignment */
}

.card:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Arrow buttons */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
}
