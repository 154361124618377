/* .footer {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  width: 95%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-contact {
  margin-bottom: 5px;
}

.footer-contact p {
  margin: 5px 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-contact svg {
  margin-right: 10px;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  display: flex;
  gap: 15px;
}

.footer-links a {
  text-decoration: none;
  color: #007bff;
}

.footer-links a:hover {
  text-decoration: underline;
} */

.footer {
  background: linear-gradient(rgba(6, 3, 21, 0.5), rgba(6, 3, 21, 0.5)),
    url("../../../public/images/map.png") center center no-repeat;
  background-size: cover;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid #ffffff;
  border-radius: 35px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}
