.marine-operations {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  max-width: 800px;
  margin: auto;
  text-align: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0; /* Fully transparent */
    transform: translateY(-10px); /* Start slightly higher */
  }
  100% {
    opacity: 1; /* Fully opaque */
    transform: translateY(0); /* Back to original position */
  }
}

@keyframes slideIn {
  0% {
    opacity: 0; /* Fully transparent */
    transform: translateY(10px); /* Start slightly lower */
  }
  100% {
    opacity: 1; /* Fully opaque */
    transform: translateY(0); /* Back to original position */
  }
}

h1 {
  color: #2c3e50;
  opacity: 0; /* Start hidden */
  animation: fadeIn 0.8s ease forwards; /* Apply fade-in animation */
  animation-delay: 0.2s; /* Delay for the main heading */
}

h2 {
  color: #34495e;
  margin-top: 20px;
  opacity: 0; /* Start hidden */
  animation: fadeIn 0.8s ease forwards; /* Apply fade-in animation */
  animation-delay: 0.4s; /* Delay for the subtitle */
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.card {
  background-color: #ecf0f1;
  border-radius: 5px;
  padding: 15px;
  width: 250px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: left;
  opacity: 0; /* Start hidden */
  animation: slideIn 0.5s ease forwards; /* Apply slide-in animation */
  animation-delay: 0.6s; /* Delay for cards */
}

.card:nth-child(odd) {
  animation-delay: 0.8s; /* Slight delay for odd cards */
}

.card-image {
  max-width: 100%;
  border-radius: 5px;
}

.additional-text {
  font-size: 0.9em;
  color: #7f8c8d;
}

button.shop-now {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Add transform transition */
}

button.shop-now:hover {
  background-color: #2980b9; /* Darker color on hover */
  transform: scale(1.05); /* Scale up on hover */
}
