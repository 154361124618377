/* 

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.logo img {
  height: 50px;
}

.nav-menu {
  display: flex;
  list-style: none;
  gap: 20px;
}

.nav-menu li a {
  text-decoration: none;
  color: white;
}


.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
}


@media (max-width: 768px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .nav-menu.open {
    display: flex;
  }

  .hamburger {
    display: block;
    color: rgb(250, 245, 245);
  }

  .logo {
    margin: auto;
  }
} */

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Spaces out logo, menu, and hamburger */
  padding: 10px 20px;
  background-color: #333; /* Example background color */
  color: white;
  position: relative;
  border-bottom: 2px solid rgba(255, 255, 255, 0.08);
}

.logo {
  order: 0; /* Ensures the logo is on the left */
}

.logo img {
  max-height: 50px;
  cursor: pointer;
}

.nav-menu {
  display: flex;
  flex-grow: 1; /* Allows the menu to expand across available space */
  justify-content: center; /* Centers the menu */
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-menu li {
  margin: 0 15px;
}

.nav-menu a {
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 24px;
  order: 2; /* Places hamburger on the right */
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    background-color: #333;
    position: absolute;
    top: 60px; /* Adjusted to avoid overlapping */
    left: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .nav-menu.open {
    display: flex;
  }

  .hamburger {
    display: block;
    color: rgb(250, 245, 245);
  }
}
