.marine-products-container {
  text-align: center;
  padding: 40px;
  background-color: #f4f6f9;
}

.marine-products-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #333;
}

.marine-products-subtitle {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #666;
}

.marine-products-expertise-title {
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: #777;
}

.products-card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.product-card {
  background-color: white;
  border: 1px solid #007bff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, border-color 0.3s;
  width: 300px;
}

.product-card:hover {
  transform: translateY(-5px);
  border-color: #0056b3;
}

.product-image {
  width: 100%;
  height: auto;
}

.product-title {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #333;
}

.product-description {
  font-size: 1rem;
  margin-bottom: 15px;
  color: #555;
  padding: 0 10px;
}

.discover-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.discover-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .products-card-container {
    flex-direction: column;
    align-items: center;
  }

  .product-card {
    width: 90%;
  }
}
