.content {
  display: flex;
  max-width: 1200px;
  background-color: white;
  animation: fadeIn 0.6s ease-out; /* Apply fade-in animation */
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Padding for image */
}

.spare-image {
  max-width: 80%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Rounded corners */
}

.text-container {
  flex: 1;
  padding: 20px; /* Padding for text */
  display: flex;
  flex-direction: column; /* Column layout */
  justify-content: center; /* Center content vertically */
}

@keyframes fadeIn {
  0% {
    opacity: 0; /* Fully transparent */
    transform: translateY(20px); /* Start slightly lower */
  }
  100% {
    opacity: 1; /* Fully opaque */
    transform: translateY(0); /* Back to original position */
  }
}

@keyframes slideIn {
  0% {
    opacity: 0; /* Fully transparent */
    transform: translateY(20px); /* Start slightly lower */
  }
  100% {
    opacity: 1; /* Fully opaque */
    transform: translateY(0); /* Back to original position */
  }
}

.title {
  font-size: 2.5rem;
  color: #000000;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif; /* Custom font */
  text-transform: uppercase; /* Uppercase letters */
  letter-spacing: 1px; /* Spacing between letters */
  opacity: 0; /* Start hidden */
  animation: slideIn 0.5s ease forwards; /* Apply slide-in animation */
  animation-delay: 0.2s; /* Delay for the title */
}

.subTitle {
  font-size: 1.8rem;
  color: #1f2936; /* Different color for subheadings */
  margin: 5px 0;
  font-family: "Roboto", sans-serif;
  opacity: 0; /* Start hidden */
  animation: slideIn 0.5s ease forwards; /* Apply slide-in animation */
  animation-delay: 0.4s; /* Delay for the subtitle */
}

.description {
  font-size: 1rem;
  font-style: italic;
  color: #555;
  line-height: 1.6; /* Better readability */
  margin: 10px 0 20px 0; /* Bottom margin for spacing */
  opacity: 0; /* Start hidden */
  animation: slideIn 0.5s ease forwards; /* Apply slide-in animation */
  animation-delay: 0.6s; /* Delay for the description */
}

.button {
  padding: 10px 20px;
  background-color: #000000; /* Button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Roboto", sans-serif; /* Use the same font */
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Add transform transition */
}

.button:hover {
  background-color: #1e2a36; /* Darker color on hover */
  transform: scale(1.05); /* Scale up on hover */
}
