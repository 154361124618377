.page-header {
  background: linear-gradient(rgba(6, 3, 21, 0.5), rgba(6, 3, 21, 0.5)),
    url("../../../public/images/carousel-1.jpg") center center no-repeat;
  background-size: cover;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

@media (min-width: 992px) {
  .container.about {
    max-width: 100% !important;
  }

  .about-text {
    padding-right: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .about-text {
    padding-right: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .about-text {
    padding-right: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}
.about-text {
  text-align: left; /* Aligns text to the left */
  font-size: 16px; /* Optional: Adjust font size for readability */
  line-height: 1.8; /* Improves readability by adjusting line height */
}

.about-text h6 {
  text-align: left; /* Ensure section headings are aligned left */
  font-weight: bold;
  color: #6c757d; /* Optional: Add a secondary color for the subheading */
}

.about-text h1 {
  text-align: left; /* Align main heading to the left */
  font-size: 2.5rem; /* Adjust the size for prominence */
}

.about-text p {
  margin-bottom: 1.5rem; /* Adds spacing between paragraphs */
}
